import React from "react";
import styled from "styled-components";

import Wrapper from "../../elements/wrapper";
import { media, TransitionMixin } from "../../helpers";

const ContactContainer = styled.div`
  padding: 40px 20px 100px;

  ${media.large`padding: 40px 0px 100px;`}

  .form-wrapper {
    max-width: 550px;
    text-align: center;
    .title-container {
      margin-bottom: 50px;
      h1 {
        font-weight: 900;
        margin: 0 0 20px;
        text-transform: uppercase;
        color: #222;
        font-size: 32px;

        ${media.medium`font-size: 42px;`}
      }

      .caption {
        text-align: left;
        font-size: 17px;

        p {
          font-size: 16px;
          font-weight: 300;
          &:last-child {
            margin-bottom: 0px;
          }
        }
      }
    }

    .form-container {
      padding-bottom: 100px;
      iframe {
        min-height: 115vh;
      }
    }
    .form-footer-wrapper {
      ${media.small`display: flex;`}
      .form-footer-container {
        text-align: left;
        flex: 1;
        padding-bottom: 20px;
        ${media.small`padding-right: 30px;`}
        h5 {
          font-size: 19px;
          margin: 0 0 30px;
        }
        p {
          margin: 0 0 5px;
          font-weight: 300;
          font-size: 16px;
        }
      }

      .map-container {
        flex: 1;
        iframe {
          border: none;
          width: 100%;
        }
      }
    }
  }
`;

const Contact = ({ data }) => {
  const page = data?.contact?.edges[0]?.node || {};
  const { formTitle, formCaption, formFooterInfo } = page?.contact_acf || {};
  return (
    <ContactContainer>
      <Wrapper className="form-wrapper">
        <div className="title-container">
          <h1>{formTitle}</h1>
          <div
            className="caption"
            dangerouslySetInnerHTML={{ __html: formCaption }}
          ></div>
        </div>
        <div className="form-container">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSd-Y0gs6_ML7G_5X59MJSxx81WsO56qaqeO2zAAJO1YKlAdBg/viewform?embedded=true (https://docs.google.com/forms/d/e/1FAIpQLSd-Y0gs6_ML7G_5X59MJSxx81WsO56qaqeO2zAAJO1YKlAdBg/viewform?embedded=true)
"
            width="640"
            height="999"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>

        <div className="form-footer-wrapper">
          <div
            className="form-footer-container"
            dangerouslySetInnerHTML={{ __html: formFooterInfo }}
          ></div>
          <div className="map-container">
            <iframe
              width="450"
              height="300"
              loading="lazy"
              allowfullscreen
              src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJO6zUwKlZwokRpYpOk8rIWsA&key=AIzaSyC3tA_OfR3hbcz_GTMG3YLTxEsjNLvSoRU"
            ></iframe>
          </div>
        </div>
      </Wrapper>
    </ContactContainer>
  );
};

export default Contact;
