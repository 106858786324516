import React, { useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Contact from "../components/page-components/contact";
import { WebsiteContext } from "../context/WebsiteContext";

const ContactPageContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const ContactPage = ({ data }) => {
  const { distanceFromTop } = useContext(WebsiteContext);
  return (
    <Layout>
      <SEO title="Contact" />
      <ContactPageContainer distanceFromTop={distanceFromTop}>
        <Contact data={data} />
      </ContactPageContainer>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query {
    contact: allWpPage(filter: { title: { eq: "Contact" } }) {
      edges {
        node {
          contact_acf {
            formTitle
            formCaption
            formFooterInfo
          }
        }
      }
    }
  }
`;
